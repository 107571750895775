<template>
  <div
    class="modal fade createNewModal"
    id="AddCommuneModal"
    tabindex="-1"
    ref="addCommuneModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="comuneForm" @submit="addCommune" :validation-schema="communeSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libellé <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Région <span class="text-danger">*</span>
                </label>
                <Field name="region" v-slot="{ field }">
                  <Multiselect
                    :options="regionOptions"
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la région"
                  />
                </Field>
                <ErrorMessage name="region" class="text-danger"/>
              </div>
            </div> -->
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch,onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { Commune } from '@/models/Commune';
import Multiselect from '@vueform/multiselect'

export default {
  name: "AddCommuneModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    // Multiselect,
  },
  props:{
    id: {
      type: Number,
      required: true,
      default:0
    },
  },
  emits: ["close",'openmodal'],

  setup: (props, { emit }) => {

    const loading = ref<boolean>(false);
    const communeSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      code: Yup.string().max(5, 'Vous ne devez pas dépasser cinq caractères').required('Le code est obligatoire'),
      // region:    Yup.string().required('La region est obligatoire'),
    });


    // const communenew = ref(props.id);
    const localItem = ref(props.id);
    const communeForm =  ref<Commune | null>(null);
    const addCommuneModalRef = ref<null | HTMLElement>(null);
    const title = ref('Ajouter une commune');
    const btntext = ref('Ajouter');
    const isupdate=ref(false);
    // const regionOptions = ref([]);


    watch(() => props.id , (newValue) => {   
      if (newValue!=0) {
        getCommune(newValue);
        isupdate.value=true;
      }
      btnTitle();
    });

    const getCommune = async (id: number) => {
      return ApiService.get("/communes/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        for (const key in donnees) {
          communeForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addCommuneModalRef.value);
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    // const fecthRegions = async (id: number) => {
    //   ApiService.get("/regions")
    //   .then(({ data }) => {
    //     const donnees = data.data.data;
    //     regionOptions.value = donnees.map((region) => {
    //       return {
    //         value: region.id,
    //         label: region.libelle,
    //       }
    //     });
    //   })
    //   .catch(({ response }) => {
    //     error(response.data.message)
    //   });
    //   }

    const btnTitle = async () => {
      if (isupdate.value) {
        title.value = "Modifier la commune";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une commune";
         btntext.value = "Ajouter";
      }
    }


    const addCommune = async (values,{ resetForm }) => {
      values = values as Commune;
      loading.value = false;
      if(isupdate.value) {
        ApiService.put(`/communes/${values.id}`,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              localItem.value = 0;
              resetForm();
              hideModal(addCommuneModalRef.value);
              isupdate.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/communes",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              resetForm();
              hideModal(addCommuneModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    }; 

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isupdate.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    onMounted( async() => {
      // fecthRegions(0)
        });
    return {title,btntext, communeSchema, addCommune, communeForm,addCommuneModalRef, isupdate,localItem,
      resetValue };
  },
};
</script>